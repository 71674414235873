<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card flat tile class="elevation-0" :loading="loading">

                <v-toolbar dense color="accent" class="elevation-0">
                    <v-toolbar-title>
                        Beitragssuche
                        <span v-if="tableSettings.term && articles.length > 0"> - {{ tableSettings.term }}</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs}">
                            <v-btn @click="articles.length > 0 ? clearSearch() : startSearch()"
                                   v-bind="attrs"
                                   v-on="on"
                                   absolute dark fab bottom right color="primary">
                                <v-icon v-if="articles.length > 0">mdi-close</v-icon>
                                <v-icon v-else>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="articles.length > 0">Suche Löschen</span>
                        <span v-else>Beiträge suchen</span>
                    </v-tooltip>
                </v-toolbar>

                <template slot="progress">
                    <v-progress-linear background-color="primary" color="white" indeterminate
                                       bottom></v-progress-linear>
                </template>

                <v-row v-if="!articles.length" align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-card class="mx-auto mt-10" elevation="0" max-width="450px">
                            <v-card-text>
                                <v-text-field
                                        dense
                                        class="mr-2 no-radius"
                                        v-model="tableSettings.term"
                                        :append-outer-icon="'mdi-send'"
                                        filled
                                        required
                                        clear-icon="mdi-close"
                                        clearable
                                        hide-details
                                        label="Suchbegriff"
                                        v-on:keydown.enter="startSearch"
                                        @click:append-outer="startSearch"
                                        @click:clear="clearTerm"
                                ></v-text-field>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-card-title v-if="articles.length">
                    <v-text-field style="max-width: 250px"
                                  v-model="tableSettings.search"
                                  append-icon="mdi-magnify"
                                  label="Filtern"
                                  single-line
                                  hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table
                        v-if="articles.length"
                        :headers="headers"
                        :items="articles"
                        :search="tableSettings.search"
                        :loading="loading"
                        loading-text="Daten werden geladen... Bitte warten"
                        :footer-props="{'items-per-page-options': [10, 25, 50, -1]}"
                        :options="tableSettings"
                        :custom-sort="customSort"
                        @update:options="updateTableSettings"
                >
                    <template v-slot:top>
                        <DialogPreviewFull
                                :dialog="preview_dialog"
                                :article_id="preview_id"
                                @close="close"
                        />
                    </template>

                    <template v-slot:item.id="{ item }">
                        {{ item.id }} 
                        <template v-if="item.reused_article_history.split('-').length > 1">
                            <v-menu>
                                <template v-slot:activator="{on: menuOn, attrs: menuAttrs}">
                                    <span v-bind="{ ...menuAttrs }" v-on="{ ...menuOn }">
                                        ({{ item.reused_article }} +)
                                    </span>
                                </template>
                                <v-list dense>
                                    <v-list-item 
                                        v-for="(reused_article, index) in  item.reused_article_history.split('-').reverse()"
                                        :key="index"
                                        style="min-height: 30px;"
                                        :to="`articles/${reused_article}`"
                                    >
                                        {{ reused_article }}
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <template v-else-if="item.reused_article">
                            <a 
                                :href="`articles/${item.reused_article}`"
                                style="text-decoration: none; color: inherit"
                            >
                                ({{ item.reused_article }})
                            </a>
                        </template>
                    </template>

                    <template v-slot:item.topic="{ item }">
                        <template v-if="instanceData.hasReadingPlan">
                            <div style="line-height: 16px">
                                <strong style="font-size: 1rem;">{{item.content_topic_name}}</strong> <br />
                                {{ item.topic_name }} {{ item.sub_topic != null ? " > " + item.sub_topic_name : "" }}
                            </div>
                        </template>
                        <template v-else>
                            {{ item.topic_name }} {{ item.sub_topic != null ? " > " + item.sub_topic_name : "" }}
                        </template>
                    </template>

                    <template v-slot:item.event_day_name="{ item }">
                        {{ item.event_day_name }}
                        <span v-if="item.event_day_topic_name && item.event_day_name"> | </span>
                        {{ item.event_day_topic_name }}
                    </template>

                    <template v-slot:item.assigned_date="{ item }">
                        <span v-if="item.assigned_date">
                            {{ item.assigned_date | luxon}}
                        </span>
                        <span v-else>-</span>
                    </template>

                    <template v-slot:item.title="{ item }">
                        <router-link :to="'/articles/' + item.id">{{ item.title }}</router-link>
                    </template>

                    <template v-slot:item.votd_reference="{ item }">
                        {{ parseBibleRef(item.votd_reference, item.votd_reference_overwrite) }}
                    </template>

                    <template v-slot:item.action="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    plain
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="previewItem(item.id)">
                                    <v-list-item-icon>
                                    <v-icon>mdi-eye-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>Vorschau</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item :to="'/articles/' + item.id">
                                    <v-list-item-icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                    <v-list-item-title>bearbeiten</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogPreviewFull from "@/components/articles/DialogPreviewFull";

    import projectMixin from "@/mixins/projectMixin"
    import bibleRefMixin from '@/mixins/bibleRefMixin'

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "ArticlesSearch",
        components: {
            MenuSetDefault,
            DialogPreviewFull,
        },
        mixins: [projectMixin, bibleRefMixin],
        data() {
            return {
                headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Titel', value: 'title'},
                    {text: 'Arbeitstitel', value: 'topic'},
                    {text: 'Anlass', value: 'event_day_name'},
                    {text: 'Anlass Thema', value: 'event_day_topic_name', align: ' d-none'},
                    {text: 'Autor', value: 'writer_name'},
                    {text: 'Status', value: 'status_name'},
                    {text: 'Projekt', value: 'project_name'},
                    {text: 'Datum', value: 'assigned_date'},
                    {text: 'Tagesvers', value: 'votd_reference'},
                    {text: 'Aktion', value: 'action', align: 'right'},
                ],
                articles: [],
                loading: false,
                preview_dialog: false,
                preview_id: null,
                tableSettings: {
                    sortBy: ['created_at'],
                    sortDesc: [true],
                    itemsPerPage: 25,
                    search: '',
                    term: '',
                },
            }
        },
        methods: {
            previewItem(itemId) {
                this.preview_id = itemId;
                this.preview_dialog = true
            },
            startSearch() {
                if (this.tableSettings.term !== '') {
                    this.loading = true;
                    ArticlesRepository.getArticlesBySearchTerm(this.tableSettings.term)
                        .then(response => {
                            if (response.data.length) {
                                this.articles = response.data;
                                if (this.$store.state.tableSettings[this.$route.path]) {
                                    this.tableSettings = this.$store.state.tableSettings[this.$route.path];
                                }
                            } else {
                                this.$store.dispatch('setMessage', 'Es wurden keine Beiträge gefunden.');
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    this.$store.dispatch('setMessage', 'Bitte geben Sie einen Suchbegriff ein.');
                }
            },
            clearTerm() {
                this.tableSettings.term = '';
            },
            clearSearch() {
                this.tableSettings.term = '';
                this.articles = [];
            },
            close() {
                this.preview_dialog = false;
                this.preview_id = null;
            },
            updateTableSettings(options) {
                this.tableSettings = options;
            },
            customSort(items, index, isDesc) {
                items.sort((a, b) => {
                    if (index == "votd_reference") {
                        if (!isDesc[0]) {
                            return this.parseBibleRef(a.votd_reference, a.votd_reference_overwrite).localeCompare(this.parseBibleRef(b.votd_reference, b.votd_reference_overwrite));
                        } else {
                            return this.parseBibleRef(b.votd_reference, b.votd_reference_overwrite).localeCompare(this.parseBibleRef(a.votd_reference, a.votd_reference_overwrite));
                        }
                    } else {
                        if (!isDesc[0]) {
                            return a[index] < b[index] ? -1 : 1;
                        } else {
                            return b[index] < a[index] ? -1 : 1;
                        }
                    }
                });
                return items;
            },
            handleContentSubTopics() {
                if(!this.instanceData.hasContentTopics && !this.instanceData.hasSubTopics) {
                    this.headers[2].text = "Thema"
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            this.$store.dispatch('setTableSettings', { path: this.$route.path, settings: this.tableSettings });
            if (this.articles.length > 0) {
                this.$store.dispatch('lastArticleSearchResult', this.articles);
            }
            next();
        },
        created() {
            if (this.$store.state.lastArticleSearchResult.length > 0) {
                this.articles = this.$store.state.lastArticleSearchResult;
            }
            if (this.$store.state.tableSettings[this.$route.path]) {
                this.tableSettings = this.$store.state.tableSettings[this.$route.path];
            }
            this.handleContentSubTopics()
        },
    }
</script>
