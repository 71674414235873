import Repository from "./Repository";
import axios from "axios";
import store from '../store/index';

const baseDomain = process.env.VUE_APP_API_ENDPOINT;
const baseURL = `${baseDomain}/`;
const resource = "/mailings";

export default {
    getAllActiveAuthors() {
        return Repository.get(`${resource}/recipients_all_active_authors/`);
    },
    sendToAllActiveAuthors(subject, text, attachment) {
        const query = axios.create({
            baseURL,
            headers: {
                'Accept': 'application/json',
                Authorization: {
                    toString() {
                        return `Bearer ${store.state.access}`
                    }
                }
            },
        })

        let formData = new FormData()
        formData.append("subject", subject);
        formData.append("text", text);
        formData.append("attachment", attachment);

        return query.post(`${resource}/all_active_authors/`, formData);
    },

    getAllPlannedAuthors(id) {
        return Repository.get(`${resource}/recipients_all_planned_authors/?project_id=${id}`);
    },
    sendToAllPlannedAuthors(id, subject, text, attachment) {
        const query = axios.create({
            baseURL,
            headers: {
                'Accept': 'application/json',
                Authorization: {
                    toString() {
                        return `Bearer ${store.state.access}`
                    }
                }
            },
        })

        let formData = new FormData()
        formData.append("project_id", id);
        formData.append("subject", subject);
        formData.append("text", text);
        formData.append("attachment", attachment);

        return query.post(`${resource}/all_planned_authors/`, formData);
    },

    getAllPlannedWithoutEdits(id) {
        return Repository.get(`${resource}/recipients_all_planned_authors_without_edits/?project_id=${id}`);
    },
    sendToAllPlannedWithoutEdits(id, subject, text, attachment) {
        const query = axios.create({
            baseURL,
            headers: {
                'Accept': 'application/json',
                Authorization: {
                    toString() {
                        return `Bearer ${store.state.access}`
                    }
                }
            },
        })

        let formData = new FormData()
        formData.append("project_id", id);
        formData.append("subject", subject);
        formData.append("text", text);
        formData.append("attachment", attachment);

        return query.post(`${resource}/all_planned_authors_without_edits/?project_id=${id}`, formData);
    },

    getAllPlannedWithUnfinished(id) {
        return Repository.get(`${resource}/recipients_all_planned_authors_with_unfinished_articles/?project_id=${id}`);
    },
    sendToAllPlannedWithUnfinished(id, subject, text, attachment) {
        const query = axios.create({
            baseURL,
            headers: {
                'Accept': 'application/json',
                Authorization: {
                    toString() {
                        return `Bearer ${store.state.access}`
                    }
                }
            },
        })

        let formData = new FormData()
        formData.append("project_id", id);
        formData.append("subject", subject);
        formData.append("text", text);
        formData.append("attachment", attachment);

        return query.post(`${resource}/all_planned_authors_with_unfinished_articles/?project_id=${id}`, formData);
    },

}
