<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card flat tile class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">
                    <v-toolbar-title>Nicht zugeordnete Beiträge</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs}">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                absolute dark fab bottom right fixed :color="showSelect && selected.length > 0 ? 'primary' :'secondary'"
                                :style="instanceData.hasFreeArticleSubmission ? 'right: 90px;' : ''"
                                @click="!showSelect || selected.length === 0 ? showSelect = !showSelect : archiveItems(selected.map(a => a.id))"
                            >
                              <v-icon>{{ showSelect && selected.length === 0 ? 'mdi-close' : 'mdi-archive-arrow-down-outline' }}</v-icon>
                            </v-btn>
                        </template>
                        <span>archivieren</span>
                    </v-tooltip>

                    <template v-if="instanceData.hasFreeArticleSubmission">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs}">
                                <v-btn @click="newItem"
                                       v-bind="attrs"
                                       v-on="on"
                                       absolute dark fab bottom right color="primary">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Beitrag erstellen</span>
                        </v-tooltip>

                        <DialogArticleNew :dialog="newArticleDialog" @close="close" @saved="saved"/>
                    </template>
                </v-toolbar>

                <v-card-title>
                    <v-text-field style="max-width: 250px"
                                  v-model="tableSettings.search"
                                  append-icon="mdi-magnify"
                                  label="Suchen"
                                  single-line
                                  hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="articles"
                        :search="tableSettings.search"
                        :loading="loading"
                        loading-text="Daten werden geladen... Bitte warten"
                        :footer-props="{'items-per-page-options': [10, 25, 50, -1]}"
                        :options="tableSettings"
                        :showSelect="showSelect"
                        :custom-sort="customSort"
                        @update:options="updateTableSettings"
                >
                    <template v-slot:top>
                        <DialogPreviewFull
                                :dialog="preview_dialog"
                                :article_id="preview_id"
                                @close="close"
                        />
                        <DialogArticleDelete
                                :dialogDelete="dialogDelete"
                                :article="articleToDelete"
                                :index="indexToDelete"
                                dialogTitle="Beitrag wirklich entfernen?"
                                @deleteArticleConfirm="deleteArticleConfirm"
                                @closeDelete="close"
                        />
                    </template>

                    <template v-slot:item.id="{ item }">
                        {{ item.id }}
                        <template v-if="item.reused_article_history.split('-').length > 1">
                            <v-menu>
                                <template v-slot:activator="{on: menuOn, attrs: menuAttrs}">
                                    <span v-bind="{ ...menuAttrs }" v-on="{ ...menuOn }">
                                        ({{ item.reused_article }} +)
                                    </span>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        v-for="(reused_article, index) in  item.reused_article_history.split('-').reverse()"
                                        :key="index"
                                        style="min-height: 30px;"
                                        :to="`articles/${reused_article}`"
                                    >
                                        {{ reused_article }}
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <template v-else-if="item.reused_article">
                            <a
                                :href="`articles/${item.reused_article}`"
                                style="text-decoration: none; color: inherit"
                            >
                                ({{ item.reused_article }})
                            </a>
                        </template>
                    </template>

                    <template v-slot:item.title="{ item }">
                        <router-link :to="'/articles/' + item.id">{{ item.title }}</router-link>
                    </template>

                    <template v-slot:item.topic="{ item }">
                        <template v-if="instanceData.hasReadingPlan">
                            <div style="line-height: 16px">
                                <strong style="font-size: 1rem;">{{item.content_topic_name}}</strong> <br />
                                {{ item.topic_name }} {{ item.sub_topic != null ? " > " + item.sub_topic_name : "" }}
                            </div>
                        </template>
                        <template v-else>
                            {{ item.topic_name }} {{ item.sub_topic != null ? " > " + item.sub_topic_name : "" }}
                        </template>
                    </template>

                    <template v-slot:item.event_day_name="{ item }">
                        {{ item.event_day_name }}
                        <span v-if="item.event_day_topic_name && item.event_day_name"> | </span>
                        {{ item.event_day_topic_name }}
                    </template>

                    <template v-slot:item.created_at="{ item }">
                        <span>
                            {{ item.created_at | luxon}}
                        </span>
                    </template>

                    <template v-slot:item.latestActivityDate="{ item }">
                        <small v-if="item.latestActivity.length > 0">
                            {{ item.latestActivity[0].user.match(/[A-Z]/g).join('') }} {{ item.latestActivity[0].datetime | luxon("relative") }}
                        </small>
                    </template>

                    <template v-slot:item.votd_reference="{ item }">
                        {{ parseBibleRef(item.votd_reference, item.votd_reference_overwrite) }}
                    </template>

                    <template v-slot:item.action="{ item }">

                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              plain
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item @click="previewItem(item.id)">
                            <v-list-item-icon>
                              <v-icon>mdi-eye-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Vorschau</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-divider/>

                          <v-list-item :to="'/articles/' + item.id">
                            <v-list-item-icon>
                              <v-icon>mdi-square-edit-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>bearbeiten</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="archiveItems([item.id])">
                            <v-list-item-icon>
                              <v-icon>mdi-archive-arrow-down-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>archivieren</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="deleteItem(item)">
                            <v-list-item-icon>
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>löschen</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                </v-data-table>
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogArticleNew from "@/components/articles/DialogArticleNew";
    import DialogPreviewFull from "@/components/articles/DialogPreviewFull";
    import DialogArticleDelete from "@/components/articles/DialogArticleDelete";

    import projectMixin from "@/mixins/projectMixin"
    import bibleRefMixin from '@/mixins/bibleRefMixin'

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "UnassignedArticles",
        components: {
            MenuSetDefault,
            DialogArticleNew,
            DialogPreviewFull,
            DialogArticleDelete,
        },
        mixins: [projectMixin, bibleRefMixin],
        data() {
            return {
                headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Titel', value: 'title'},
                    {text: 'Arbeitstitel', value: 'topic'},
                    {text: '', value: 'topic_name', align: ' d-none'},
                    {text: '', value: 'sub_topic', align: ' d-none'},
                    {text: '', value: 'content_topic_name', align: ' d-none'},
                    {text: 'Anlass', value: 'event_day_name'},
                    {text: 'Anlass Thema', value: 'event_day_topic_name', align: ' d-none'},
                    {text: 'Autor', value: 'writer_name'},
                    {text: 'Status', value: 'status_name'},
                    {text: 'Erstellt am', value: 'created_at'},
                    {text: 'Aktivität', value: 'latestActivityDate'},
                    {text: 'Tagesvers', value: 'votd_reference'},
                    {text: 'Aktion', value: 'action', align: 'right'},
                ],
                articles: [],
                selected: [],
                showSelect: false,
                loading: true,
                preview_dialog: false,
                preview_id: null,
                newArticleDialog: false,
                dialogDelete: false,
                articleToDelete: null,
                indexToDelete: null,
                tableSettings: {
                    sortBy: ['latestActivityDate'],
                    sortDesc: [true],
                    search: '',
                    itemsPerPage: 25,
                },
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                let {data} = await ArticlesRepository.getUnassignedArticles();
                data = data.map(article => ({ ...article, latestActivity: this.getLastActivity(article) }));
                data = data.map(article => ({ ...article, latestActivityDate: article.latestActivity[0].datetime }));
                this.articles = data;
                this.getTableSettings()
                this.loading = false;
            },
            getLastActivity(article) {
                let lastActivities = [{ type: 'Änderung', datetime: article.updated_at, user: article.updated_user_name}];
                if (article.last_check !== null) {
                    lastActivities = [ ...lastActivities, { type: 'Check', datetime: article.last_check.created_at, user: article.last_check.user_data.first_name + ' ' + article.last_check.user_data.last_name } ];
                }
                if (article.last_comment !== null) {
                    lastActivities = [ ...lastActivities,  { type: 'Kommentar', datetime: article.last_comment.created_at, user: article.last_comment.author_data.first_name  + ' ' + article.last_comment.author_data.last_name } ];
                }

                lastActivities.sort(function(a, b) {
                    let c = new Date(a.datetime);
                    let d = new Date(b.datetime);
                    return d-c;
                });

                return lastActivities;
            },
            previewItem(itemId) {
                this.preview_id = itemId;
                this.preview_dialog = true
            },
            newItem() {
                this.newArticleDialog = true;
            },
            saved(item) {
                this.articles.push(item);
                this.close();
            },
            close() {
                this.preview_dialog = false;
                this.newArticleDialog = false;
                this.dialogDelete = false;
                this.indexToDelete = null;
                this.articleToDelete = null;
                this.$nextTick(() => {
                    this.preview_id = null;
                })
            },
            deleteItem(item) {
                let indexToDelete = this.articles.map(x => {
                    return x.id;
                }).indexOf(item.id);
                this.dialogDelete = true;
                this.indexToDelete = indexToDelete;
                this.articleToDelete = item;
            },
            async archiveItems(items) {
              let {data} = await ArticlesRepository.archiveArticles(items);
              if (data?.success?.length > 0) {
                data.success.map(
                    a => ( this.articles.splice(this.articles.map(x => {return x.id}).indexOf(a),1))
                )
              }
              this.selected = []
              this.showSelect = false
            },
            deleteArticleConfirm (deletedArticleId) {
                this.articles.splice(deletedArticleId, 1);
                this.close()
            },
            updateTableSettings(options) {
                this.tableSettings = options;
            },
            customSort(items, index, isDesc) {
                items.sort((a, b) => {
                    if (index == "votd_reference") {
                        if (!isDesc[0]) {
                            return this.parseBibleRef(a.votd_reference, a.votd_reference_overwrite).localeCompare(this.parseBibleRef(b.votd_reference, b.votd_reference_overwrite));
                        } else {
                            return this.parseBibleRef(b.votd_reference, b.votd_reference_overwrite).localeCompare(this.parseBibleRef(a.votd_reference, a.votd_reference_overwrite));
                        }
                    } else {
                        if (!isDesc[0]) {
                            return a[index] < b[index] ? -1 : 1;
                        } else {
                            return b[index] < a[index] ? -1 : 1;
                        }
                    }
                });
                return items;
            },
            handleContentSubTopics() {
                if(!this.instanceData.hasContentTopics && !this.instanceData.hasSubTopics) {
                    this.headers[2].text = "Thema"
                }
            },
            getTableSettings() {
                if (this.$store.state.tableSettings[this.$route.path]) {
                    this.tableSettings = this.$store.state.tableSettings[this.$route.path];
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            this.$store.dispatch('setTableSettings', { path: this.$route.path, settings: this.tableSettings });
            next();
        },
        async created() {
            await this.fetch();
            this.getTableSettings()
            this.handleContentSubTopics()
        },
    }
</script>
