<template>
  <v-row class="ml-2">
    <v-col cols="12" md="6">
      <v-list>
        <v-list-item v-if="song">
          <v-avatar color="primary" size="40">
            <span class="white--text text-h6">{{ song.gl_number }}</span>
          </v-avatar>
          <v-list-item-content class="ml-3">
            <v-list-item-title class="text-h6">
              {{ song.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ song.author_info }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="goToSong(song.gl_number)">
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-else>
          <span class="text-h6">Kein Lied ausgewählt</span>
        </v-list-item>
      </v-list>
      <v-textarea
        filled
        :value="value"
        @input="$emit('input', $event)"
        counter="120"
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-tabs-items v-model="side">
        <v-tab-item>
          <v-toolbar elevation="0">
            <v-spacer />
            <v-text-field
              style="max-width: 250px"
              v-model="search"
              append-icon="mdi-magnify"
              @click:append="searchSongs"
              v-on:keyup.enter="searchSongs"
              label="Suchen"
              single-line
              hide-details
            />
          </v-toolbar>
          <div class="text-center" v-if="loading">
            <v-progress-circular
              class="my-15"
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
          <v-list style="max-height: 600px" class="overflow-y-auto" v-else>
            <v-list-item
              v-for="song in songs"
              :key="song.id"
              @click="goToSong(song.gl_number)"
            >
              <v-list-item-avatar color="primary" size="40">
                <span class="white--text text-h6">{{ song.gl_number }}</span>
              </v-list-item-avatar>
              <v-list-item-content class="ml-3">
                <v-list-item-title class="text-h6">
                  {{ song.title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ song.author_info }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-tab-item>
        <v-tab-item>
          <div class="text-center">
            <v-progress-circular
              v-if="loading"
              class="my-15"
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
          <div v-if="!loading">
            <v-list-item elevation="0">
              <v-btn icon @click="switchSide">
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-avatar color="primary" size="40">
                <span class="white--text text-h6">
                  {{ currentlyShowedSong.gl_number }}
                </span>
              </v-avatar>
              <v-list-item-content class="ml-3">
                <v-list-item-title class="text-h6">
                  {{ currentlyShowedSong.title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ currentlyShowedSong.author_info }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-avatar
                  color="green"
                  size="30"
                  v-if="song && song.gl_number == currentlyShowedSong.gl_number"
                >
                  <v-icon>mdi-check-bold</v-icon>
                </v-avatar>
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="selectSong" icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Diesen Song wählen</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <div
              v-html="currentlyShowedSong.full_text"
              style="max-height: 600px"
              class="overflow-y-auto"
            />
            <div
              class="text-right text-caption mr-4"
              v-if="currentlyShowedSong.copyright_info"
            >
              © {{ currentlyShowedSong.copyright_info }}
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory"
const SongsRepository = RepositoryFactory.get("songs")

import projectMixin from "@/mixins/projectMixin"

export default {
  name: "SongSelection",
  props: {
    song: Object,
    value: String,
  },
  emits: ["input"],
  mixins: [projectMixin],
  data() {
    return {
      songs: [],
      selected: [],
      side: 0,
      sides: ["list", "text"],
      search: "",
      showedSong: 0,
      currentlyShowedSong: {},
      loading: false,
    }
  },
  async created() {
    this.loading = true
    const { data } = await SongsRepository.get()
    this.songs = data
    this.loading = false
  },
  methods: {
    switchSide() {
      if (this.side == 0) {
        this.side = 1
      } else {
        this.side = 0
      }
    },
    async searchSongs() {
      let { data } = await SongsRepository.getBySearch(this.search)
      this.songs = data
    },
    async goToSong(goTo) {
      this.side = 1
      this.loading = true
      const { data } = await SongsRepository.getByNumber(goTo)
      this.currentlyShowedSong = data
      this.loading = false
    },
    selectSong() {
      this.$emit("songSelected", this.currentlyShowedSong)
    },
  },
  watch: {},
}
</script>
