<template>
  <v-dialog
    v-model="dialog"
    max-width="1600px"
    persistent
    @click:outside="close"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="elevation-0">
      <v-card-title class="text-h5 accent">
        Beitragsplanung
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab" fixed-tabs class="mt-2">
          <v-tab v-for="i in 12" :key="i" class="text-h6">
            {{ months[i - 1].name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="i in 12" :key="i">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th>Wochentag</th>
                    <th>Arbeitstitel</th>
                    <th>Autor</th>
                    <th>Lernvers</th>
                    <th>Lernversposition</th>
                    <th v-if="instanceData.hasEditorSelection">Lektor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="j in getAllDaysInMonth(selectedProject.year, i - 1)"
                    :key="j.toString()"
                  >
                    <td>
                      {{ j.toLocaleDateString("en-CH") }}
                    </td>
                    <td>
                      {{ weekdays[j.getDay()] }}
                    </td>
                    <td>
                      <template v-for="article in articles">
                        <template v-for="topic in topics">
                          {{
                            new Date(
                              article.assigned_date
                            ).toLocaleDateString() == j.toLocaleDateString() &&
                            topic.id == article.content_topic
                              ? topic.name
                              : ""
                          }}
                        </template>
                      </template>
                    </td>
                    <td>
                      <template v-for="article in articles">
                        {{
                          new Date(
                            article.assigned_date
                          ).toLocaleDateString() == j.toLocaleDateString()
                            ? article.writer_data.first_name +
                              " " +
                              article.writer_data.last_name
                            : ""
                        }}
                      </template>
                    </td>
                    <td>
                      <template v-for="article in articles">
                        {{
                          new Date(
                            article.assigned_date
                          ).toLocaleDateString() == j.toLocaleDateString()
                            ? article.learning_verse
                            : ""
                        }}
                      </template>
                    </td>
                    <td>
                      <template v-for="article in articles">
                        {{
                          new Date(
                            article.assigned_date
                          ).toLocaleDateString() == j.toLocaleDateString()
                            ? article.learning_verse_ref
                            : ""
                        }}
                      </template>
                    </td>
                    <td v-if="instanceData.hasEditorSelection">
                      <template v-for="article in articles">
                        {{ article.editor_name }}
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <!-- <v-card-actions>
          <v-btn color="primary" text @click="close()">Schließen</v-btn>
        </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import projectMixin from "@/mixins/projectMixin"
import { RepositoryFactory } from "@/repositories/RepositoryFactory"
const TopicsRepository = RepositoryFactory.get("topics")
const PlannedArticlesRepository = RepositoryFactory.get("plannedArticles")

export default {
  name: "DialogPlannedArticles",
  mixins: [projectMixin],
  props: {
    dialog: Boolean,
    selectedProject: Object,
  },
  data() {
    return {
      valid: false,
      weekdays: [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
      ],
      months: [
        { name: "Januar", length: 31 },
        { name: "Februar", length: 28 },
        { name: "März", length: 31 },
        { name: "April", length: 30 },
        { name: "Mai", length: 31 },
        { name: "Juni", length: 30 },
        { name: "Juli", length: 31 },
        { name: "August", length: 31 },
        { name: "September", length: 30 },
        { name: "Oktober", length: 31 },
        { name: "November", length: 30 },
        { name: "Dezember", length: 31 },
      ],
      articles: [],
      selectedArticle: {},
      archiveID: 0,
      archiveArticle: {},
      topics: [],
      authors: [],
      allPlannedArticles: [],
      tab: 0,
    }
  },
  async created() {
    const { data: topicsData } = await TopicsRepository.getContentTopics()
    this.topics = topicsData

    const { data: plannedArticlesData } = await PlannedArticlesRepository.get()
    this.allPlannedArticles = plannedArticlesData
    this.articles = plannedArticlesData.filter(
      (article) => article.project == this.selectedProject.id
    )
  },
  watch: {
    selectedProject: function() {
      this.articles = this.allPlannedArticles.filter(
        (article) => article.project == this.selectedProject.id
      )
    },
  },
  methods: {
    close() {
      this.$emit("close")
    },
    getAllDaysInMonth(year, month) {
      const date = new Date(year, month, 1)

      const dates = []

      while (date.getMonth() === month) {
        dates.push(new Date(date))
        date.setDate(date.getDate() + 1)
      }

      return dates
    },
  },
}
</script>
