import Repository from "./Repository";

const resource = "/article_plannings";

export default {
    get() {
        return Repository.get(`${resource}/`);
    },
    getById(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    getByWriter(writer) {
        return Repository.get(`${resource}/?writer=${writer}&used=0`);
    },
    add(assignedDate, project, writer, contentTopic, reusedArticle, learning_verse, learning_verse_ref, assigned_editor = false, recommended_bible_reference = false) {
        let payload = { 'assigned_date': assignedDate, 'project': project, 'writer': writer, 'content_topic': contentTopic };

        if (reusedArticle) payload.reused_article = reusedArticle;
        payload.learning_verse = learning_verse
        payload.learning_verse_ref = learning_verse_ref
        if (assigned_editor) payload.assigned_editor = assigned_editor
        if (recommended_bible_reference) payload.recommended_bible_reference = recommended_bible_reference

        return Repository.post(`${resource}/`, payload);
    },
    createFromArchive(id) {
        return Repository.get(`${resource}/${id}/create_from_reused_article/`);
    },
    update(id, assignedDate, project, writer, contentTopic, reusedArticle = null, learning_verse, learning_verse_ref, assigned_editor = false, recommended_bible_reference = false) {
        let payload = { 'assigned_date': assignedDate, 'project': project, 'writer': writer, 'content_topic': contentTopic };

        payload.reused_article = reusedArticle;
        payload.learning_verse = learning_verse
        payload.learning_verse_ref = learning_verse_ref
        if (assigned_editor) payload.assigned_editor = assigned_editor
        if (recommended_bible_reference) payload.recommended_bible_reference = recommended_bible_reference

        return Repository.put(`${resource}/${id}/`, payload);
    },
}
