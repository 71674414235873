<template>
  <v-dialog
    v-model="dialog"
    max-width="350px"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title>Statusänderung</v-card-title>
      <v-card-text>
        Den Status der {{ selected.length }} ausgewählten Beiträge ändern?
        <v-select :items="statusOptions" v-model="statusToSet"></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">Abbrechen</v-btn>
        <v-btn
          color="primary"
          elevation="0"
          @click="changeStatusConfirm"
          :loading="loading"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory"

const ArticlesRepository = RepositoryFactory.get("articles")

export default {
  name: "DialogChangeMultipleStatus",
  props: {
    dialog: Boolean,
    selected: Array,
  },
  data() {
    return {
      statusOptions: [],
      statusToSet: "30",
      loading: false,
    }
  },
  async created() {
    await this.getStatusOptions()
  },
  methods: {
    async changeStatusConfirm() {
      this.loading = true
      this.$emit("loading")
      for (let article of this.selected) {
        await ArticlesRepository.setStatus(article.id, this.statusToSet).catch(
          (error) => {
            console.log(error)
            this.$emit("closeDialog")
          }
        )
      }
      this.loading = false
      this.$emit("changeStatus", false)
      this.$emit("closeDialog")
    },
    async getStatusOptions() {
      const { data } = await ArticlesRepository.getStatusOptions()
      const keys = Object.keys(data)
      for (let i = 0; i < keys.length; i++) {
        this.statusOptions.push({
          value: keys[i],
          text: data[keys[i]],
        })
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
  },
}
</script>

<style scoped></style>
