<template>
    <v-app id="inspire">

        <MenuSetDefault/>
        <ArticleComments
                v-if="article.id !== undefined"
                :open="commentDrawer"
                :articleId="article.id"
                :author="article.writer_data"
                :comment_author="article.comment_author"
                :created_at="article.created_at"
                @drawerOpened="handleDrawerChange('commentDrawer', $event)"
                @commentCount="updateCommentCount"
        />

        <v-main>
            <v-card flat tile class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">
                    <v-btn icon @click="$router.go(-1)">
                        <v-icon color="primary">mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-toolbar-title>
                        Beitrag ID: {{ article.id }}
                        <template v-if="article.reused_article_history?.split('-').length > 1">
                            <v-menu>
                                <template v-slot:activator="{on: menuOn, attrs: menuAttrs}">
                                    <span v-bind="{ ...menuAttrs }" v-on="{ ...menuOn }">
                                        ({{ article.reused_article }} +)
                                    </span>
                                </template>
                                <v-list dense>
                                    <v-list-item 
                                        v-for="(reused_article, index) in  article.reused_article_history?.split('-').reverse()"
                                        :key="index"
                                        style="min-height: 30px;"
                                        :to="`${reused_article}`"
                                    >
                                        {{ reused_article }}
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <template v-else-if="article.reused_article">
                            <a 
                                :href="`${article.reused_article}`" 
                                style="text-decoration: none; color: inherit"
                            >
                                ({{ article.reused_article }})
                            </a>
                        </template>
                        <span v-if="article.assigned_date && article.assigned_date !== undefined">
                            - {{ article.assigned_date | luxon("EEE DD") }}
                        </span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs}">
                            <v-btn icon @click="editItem(article.id)"
                                   v-bind="attrs"
                                   v-on="on">
                                <v-icon>mdi-square-edit-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Beitrag bearbeiten</span>
                    </v-tooltip>

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon @click="emailDialog = true" :disabled="myArticle">
                                <v-icon>mdi-email-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Email an Autor</span>
                    </v-tooltip>

                    <DialogSendMail @close="close" :dialog="emailDialog" :article="article"/>

                    <DialogArticleEdit
                            :dialog="editDialog"
                            :article_id="article.id"
                            @edited="edited"
                            @close="close"
                            @intermediateUpdate="updateArticle"
                    />

                    <DialogPreviewFull
                            :dialog="preview_dialog"
                            :article_id="preview_id"
                            @close="close"
                    />

                    <DialogArticleHistory
                            :dialog="historyDialog"
                            :article="article"
                            @close="close"
                    />

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs}">
                            <v-btn icon @click="previewItem(article.id)"
                                   v-bind="attrs"
                                   v-on="on">
                                <v-icon>mdi-text-box-search-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Vorschau</span>
                    </v-tooltip>

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs}">
                            <v-btn icon @click.stop="historyDialog = !historyDialog"
                                   v-bind="attrs"
                                   v-on="on">
                                <v-icon>mdi-history</v-icon>
                            </v-btn>
                        </template>
                        <span>Verlauf</span>
                    </v-tooltip>

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs}">
                            <v-badge
                                    :content="commentCount"
                                    :value="commentCount"
                                    color="primary"
                                    offset-x="20"
                                    offset-y="25"
                                    overlap
                            >
                            <v-btn icon @click.stop="commentDrawer = !commentDrawer"
                                   v-bind="attrs"
                                   v-on="on">
                                <v-icon>mdi-forum-outline</v-icon>
                            </v-btn>
                            </v-badge>
                        </template>
                        <span>Kommentare</span>
                    </v-tooltip>
                </v-toolbar>

                <v-row dense class="pa-4">
                    <v-col cols="12" sm="12" md="8" class="">
                        <v-row class="ma-1 mb-3">
                            <v-col cols="12" :md="`${instanceData.hasDoubleVotd ? '6' : '12'}`" class="pa-0">
                                <v-card height="100%">
                                    <v-card-text>
                                        <div class="text-sm-h5 font-italic" style="white-space: pre-wrap;">{{ article.votd }}</div>
                                        <div class="text-sm-subtitle-2">
                                            <template v-if="article.votd_is_excerpt">
                                                Aus
                                            </template>
                                            {{ votd_ref }}
                                            <template v-if="instanceData.hasDoubleVotd">
                                                (ELB)
                                            </template>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-0 pl-md-4 pt-4 pt-md-0" v-if="instanceData.hasDoubleVotd">
                                <v-card height="100%">
                                    <v-card-text>
                                        <div class="text-sm-h5 font-italic" style="white-space: pre-wrap;">{{ article.votd_alt }}</div>
                                        <div class="text-sm-subtitle-2">
                                            <template v-if="article.votd_alt_is_excerpt">
                                                Aus
                                            </template>
                                            {{ votd_alt_ref }}
                                            <template v-if="instanceData.hasDoubleVotd">
                                                (NeÜ)
                                            </template>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-card class="ma-1 mb-3 flex flex-column">
                            <v-card-title>{{ article.title }}</v-card-title>
                            <v-card-text>
                                <div class="article-text-preview" v-html="article.text"></div>
                                <small  v-if="article.text !== undefined" :class="this.htmlToText(article.text).length > instanceData.articleTextLength ? 'text--warn' : ''">{{ this.htmlToText(article.text).length }}/{{instanceData.articleTextLength}}</small>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
<!--                                <v-tooltip left>-->
<!--                                    <template v-slot:activator="{ on, attrs }">-->
<!--                                        <v-btn icon v-bind="attrs" v-on="on"  @click="editTextDialog = true">-->
<!--                                            <v-icon left >mdi-square-edit-outline</v-icon>-->
<!--                                        </v-btn>-->
<!--                                    </template>-->
<!--                                    <span>Text bearbeiten</span>-->
<!--                                </v-tooltip>-->

<!--                                <DialogArticleTextEdit @close="close" @edited="updateArticle" :dialog="editTextDialog" :article="article"/>-->
                            </v-card-actions>
                        </v-card>

                        <v-card class="ma-1">
                            <v-card-text>
                                <v-list class="py-0" dense>
                                    <v-list-item 
                                        v-if="instanceData.articleQuestionField"
                                        class="pa-0 "
                                    >
                                        <v-list-item-avatar size="33"
                                            class="pa-0 my-2"
                                        >
                                            <v-icon class="primary" small dark>mdi-help</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content
                                            class="pa-0"
                                        >
                                            <div v-html="article.question" class="hintQuestion"/>
                                        </v-list-item-content>
                                    </v-list-item>
    
                                    <v-list-item
                                        v-if="instanceData.articleHintField"
                                        class="pa-0" 
                                    >
                                        <v-list-item-avatar size="33"
                                            class="pa-0 my-2"
                                        >
                                            <v-icon class="primary" small dark>mdi-exclamation-thick</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content
                                            class="pa-0"
                                        >
                                            <div v-html="article.hint" class="hintQuestion"/>
                                        </v-list-item-content>
                                    </v-list-item>
    
                                    <v-list-item class="font-weight-bold font-italic pa-0 ">
                                        <v-list-item-avatar size="33"
                                            class="pa-0 my-2"
                                        >
                                            <v-icon class="primary" small dark>mdi-book</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content
                                            class="pa-0"
                                        >
                                            {{ article.recommended_bible_reference }}
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" class="">
                        <ArticleInformationCard :loading="loading" :article="article" @updateArticle="updateArticle"></ArticleInformationCard>
                        <v-card class="ma-1 mt-3" v-if="instanceData.hasSongs">
                            <v-card-text>
                                <div class="text-sm-h5 font-italic" style="white-space: pre-wrap;">{{ article.song_text }}</div>
                                <v-list style="max-height: 600px" class="overflow-y-auto pb-0">
                                    <v-list-item class="font-weight-bold font-italic">
                                        <v-list-item-avatar color="primary" size="40">
                                            <span class="white--text text-h6">
                                                {{ article.used_song_text_data?.gl_number }}
                                            </span>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="ml-3">
                                            <v-list-item-title class="text-h6">
                                            {{ article.used_song_text_data?.title }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            {{ article.used_song_text_data?.author_info }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import ArticleComments from "@/components/articles/ArticleComments";
    import ArticleInformationCard from "@/components/articles/ArticleInformationCard";
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogArticleEdit from "@/components/articles/DialogArticleEdit";
    import DialogArticleHistory from "@/components/articles/DialogArticleHistory";
    import DialogPreviewFull from "@/components/articles/DialogPreviewFull";
    import DialogSendMail from "@/components/users/DialogSendMail";
    // import DialogArticleTextEdit from "@/components/articles/DialogArticleTextEdit";

    import bibleRefMixin from '@/mixins/bibleRefMixin'
    import projectMixin from '@/mixins/projectMixin'

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "Article",
        mixins: [bibleRefMixin, projectMixin],
        components: {
            MenuSetDefault,
            ArticleComments,
            ArticleInformationCard,
            DialogArticleEdit,
            DialogArticleHistory,
            DialogPreviewFull,
            DialogSendMail,
            // DialogArticleTextEdit,
        },
        data() {
            return {
                article: {},
                loading: true,
                commentDrawer: false,
                editDialog: false,
                historyDialog: false,
                emailDialog: false,
                editTextDialog: false,
                sheet: false,
                preview_dialog: false,
                preview_id: null,
                commentCount: 0,
                votd_ref: '',
                votd_alt_ref: '',
            }
        },
        methods: {
            async fetch() {
                let self = this;
                let articleId = self.$route.params.articleId;
                this.loading = true;
                const {data} = await ArticlesRepository.getArticle(articleId);
                this.article = data;
                this.votd_ref = await this.parseBibleRef(this.article.votd_reference, this.article.votd_reference_overwrite);
                this.votd_alt_ref = await this.parseBibleRef(this.article.votd_alt_reference, this.article.votd_reference_overwrite);
                this.loading = false;
            },
            async updateArticle(data) {
                this.article = data;
                this.votd_ref = await this.parseBibleRef(data.votd_reference, data.votd_reference_overwrite);
                this.votd_alt_ref = await this.parseBibleRef(data.votd_alt_reference, data.votd_reference_overwrite);
            },
            handleDrawerChange(type, isOpen) {
                if (type === 'commentDrawer') {
                    this.commentDrawer = isOpen
                }
            },
            editItem() {
                this.editDialog = true;
            },
            async edited(editedItem) {
                this.article = editedItem;
                this.votd_ref = await this.parseBibleRef(editedItem.votd_reference, editedItem.votd_reference_overwrite);
                this.close();
            },
            close() {
                this.editDialog = false;
                this.historyDialog = false;
                this.preview_dialog = false;
                this.emailDialog = false;
                this.editTextDialog = false;
            },
            previewItem(itemId) {
                this.preview_id = itemId;
                this.preview_dialog = true;
            },
            updateCommentCount(count) {
                if (this.article.comment_author) {
                    this.commentCount = count + 1;
                } else {
                    this.commentCount = count;
                }
                this.updateCommentDrawer();
            },
            htmlToText (html) {
                return html.replace(/<[^>]*>?/gm, '')
            },
            updateCommentDrawer() {
              if (this.$vuetify.breakpoint.lgAndUp && this.commentCount > 0) {
                this.commentDrawer = true;
              }
            }
        },
        async created() {
            await this.fetch();
            this.updateCommentDrawer();
        },
        computed: {
            myArticle() {
                if (this.article.writer_data !== undefined) {
                    return this.$store.state.user.id === this.article.writer_data.id;
                } else {
                    return false;
                }
            },
        },
        watch: {
            '$route.params.articleId': function () {
                this.fetch();
            }
        },
    }
</script>

<style scoped>
    .pre-formatted {
        white-space: pre;
    }
    .text--warn {
        color: #c01500;
    }
    .hintQuestion >>> p {
        margin-bottom: 0px !important;
    }
</style>
