<template>
    <v-dialog v-model="dialog" max-width="800px" @click:outside="close" @keydown.esc="close" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card :loading="loading">
            <template slot="progress">
                <v-progress-linear background-color="primary" color="white" indeterminate bottom></v-progress-linear>
            </template>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" :sm="$store.getters.isAdmin ? 5 : 6">
                                <v-text-field
                                        v-model="editedItem.first_name"
                                        label="Vorname*"
                                        :rules="[v => !!v || 'Vorname ist ein Pflichtfeld']"
                                        required
                                        filled
                                        dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" :sm="$store.getters.isAdmin ? 5 : 6">
                                <v-text-field
                                        v-model="editedItem.last_name"
                                        label="Nachname*"
                                        :rules="[v => !!v || 'Nachname ist ein Pflichtfeld']"
                                        required
                                        filled
                                        dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="2" v-if="$store.getters.isAdmin">
                                <v-text-field
                                        v-model="editedItem.short"
                                        label="Kürzel"
                                        :rules="[
                                            v => users.filter(u => u.short === v && u.id !== editedItem.id).length === 0 || 'Kürzel vergeben',
                                            v => !!v || 'Kürzel benötigt',
                                            ]"
                                        filled
                                        dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-text-field
                                        v-model="editedItem.username"
                                        label="Benutzername*"
                                        :rules="[v => !!v || 'Benutzername ist ein Pflichtfeld']"
                                        required
                                        filled
                                        dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-text-field
                                        v-model="editedItem.email"
                                        label="Email-Adresse*"
                                        :rules="[v => !!v || 'Email-Adresse ist ein Pflichtfeld', rules.email]"
                                        required
                                        filled
                                        dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" v-if="editedIndex === -1">
                                <!-- IMPORTANT: v-if needed to hide when User is edited!! -->
                                <v-text-field
                                        v-model="editedItem.password"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        label="Passwort*"
                                        :rules="[v => !!v || 'Passwort ist ein Pflichtfeld']"
                                        :type="showPassword ? 'text' : 'password'"
                                        required
                                        filled
                                        dense
                                        @click:append="showPassword = !showPassword"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <span class="form-header">Adresse</span>

                        <v-divider class="mb-3"/>

                        <v-row>
                            <v-col cols="12" sm="5">
                                <v-text-field
                                        v-model="editedItem.street"
                                        label="Strasse & Hausnummner"
                                        filled
                                        dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="2">
                                <v-text-field
                                        v-model="editedItem.zip_code"
                                        label="PLZ"
                                        filled
                                        dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="5">
                                <v-text-field
                                        v-model="editedItem.city"
                                        label="Stadt"
                                        filled
                                        dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <span class="form-header">Kontaktdaten</span>

                        <v-divider class="mb-3"/>

                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                        v-model="editedItem.tel_private"
                                        label="Telefon privat"
                                        filled
                                        dense
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-text-field
                                        v-model="editedItem.tel_business"
                                        label="Telefon geschäftlich"
                                        filled
                                        dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <span class="form-header">Zusätzliche Angaben</span>

                        <v-divider class="mb-3"/>

                        <v-row>
                            <v-col cols="12" sm="6">
                                <template>
                                    <v-menu
                                            ref="menu"
                                            v-model="showDatePicker"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="birthdayFormatted"
                                                    label="Geburtstag"
                                                    prepend-icon="mdi-calendar"
                                                    @blur="birthday = parseDate(birthdayFormatted)"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    filled
                                                    dense
                                                    clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                                ref="picker"
                                                v-model="birthday"
                                                :max="new Date().toISOString().substr(0, 10)"
                                                @change="showDatePicker = false"
                                                min="1940-01-01"
                                        ></v-date-picker>
                                    </v-menu>
                                </template>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                        v-model="editedItem.gender"
                                        :items="genders"
                                        item-value="key"
                                        item-text="value"
                                        label="Geschlecht"
                                        required
                                        filled
                                        dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" v-if="$store.getters.isAdmin">
                                <v-autocomplete
                                        v-model="editedItem.church"
                                        :items="churches"
                                        item-value="id"
                                        item-text="name"
                                        label="Gemeinde"
                                        required
                                        filled
                                        dense
                                        clearable
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="editedIndex && $store.getters.isAdmin">
                                <v-autocomplete
                                        v-model="editedItem.status"
                                        :items="statusOptions"
                                        item-value="key"
                                        item-text="value"
                                        label="Status"
                                        required
                                        filled
                                        dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="editedIndex && $store.getters.isAdmin">
                                <v-text-field
                                        v-model="editedItem.demand_count"
                                        type="number"
                                        label="Vorgabe"
                                        filled
                                        dense
                                        append-outer-icon="mdi-plus"
                                        @click:append-outer="incrementDemandCount()"
                                        prepend-icon="mdi-minus"
                                        @click:prepend="decrementDemandCount()"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="editedIndex && $store.getters.isAdmin">
                                <v-text-field
                                        v-model="editedItem.comment"
                                        label="Kommentar"
                                        filled
                                        dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="primary" text @click="close">Schließen</v-btn>
                    <v-btn color="primary" elevation="0" @click="save">Speichern</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const UsersRepository = RepositoryFactory.get('users');
    const ChurchesRepository = RepositoryFactory.get('churches');

    export default {
        name: "DialogUser",
        props: {
            users: Array,
            dialog: Boolean,
            editedItem: Object,
            editedIndex: Number,
            formTitle: String,
        },
        data() {
            return {
                loading: false,
                valid: true,
                genders: [],
                churches: [],
                statusOptions: [],
                showDatePicker: false,
                showPassword: false,
                birthday: this.computedBirthday,
                short: this.editedItem.short,
                birthdayFormatted: this.formatDate(this.editedItem.birthday),
                rules: {
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    }
                },
            }
        },
        methods: {
            async save() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    if (this.editedIndex === -1) {
                        UsersRepository.add(this.editedItem.username, this.editedItem.first_name, this.editedItem.last_name, this.editedItem.email, this.editedItem.password, this.editedItem.short, this.editedItem.gender, this.birthday, this.editedItem.street, this.editedItem.zip_code, this.editedItem.city, this.editedItem.tel_private, this.editedItem.tel_business, this.editedItem.church, this.editedItem.status, this.editedItem.demand_count, this.editedItem.comment)
                            .then(response => {
                                this.close();
                                this.saved(response.data);
                                this.loading = false;
                                this.$store.dispatch('setMessage', 'Der Benutzer "' + this.editedItem.first_name + ' ' + this.editedItem.last_name + '" wurde erfolgreich gespeichert.');
                            })
                            .catch(err => {
                                    this.close();
                                    this.loading = false;
                                    console.log(err);
                                    // this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                                }
                            );
                    } else {
                        UsersRepository.update(this.editedItem.id, this.editedItem.username, this.editedItem.first_name, this.editedItem.last_name, this.editedItem.email, this.editedItem.short, this.editedItem.gender, this.birthday, this.editedItem.street, this.editedItem.zip_code, this.editedItem.city, this.editedItem.tel_private, this.editedItem.tel_business, this.editedItem.church, this.editedItem.status, this.editedItem.demand_count, this.editedItem.comment)
                            .then(response => {
                                this.close();
                                this.saved(response.data);
                                this.loading = false;
                                this.$store.dispatch('setMessage', 'Der Benutzer "' + this.editedItem.first_name + ' ' + this.editedItem.last_name + '" wurde erfolgreich geändert.');
                            })
                            .catch(err => {
                                    this.close();
                                    this.loading = false;
                                    console.log(err);
                                    // this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                                }
                            );
                    }

                }
            },
            saved(item) {
                this.$emit('saved', item);
            },
            close() {
                this.$emit('close', this.editedItem);
            },
            reset() {
                this.$refs.form.reset()
            },
            async fetchGenderOptions() {
                this.loading = true;
                const {data} = await UsersRepository.getGenderOptions();
                this.genders = Object.keys(data).map(key => {
                    return {'key': key, 'value': data[key]}
                });
                this.loading_obj.genders = false;
                this.check_loading();
            },
            async fetchChurches() {
                this.loading = true;
                const {data} = await ChurchesRepository.get();
                this.churches = data;
                this.loading_obj.churches = false;
                this.check_loading();
            },
            async fetchStatusOptions() {
                this.loading = true;
                const {data} = await UsersRepository.getStatusOptions();
                this.statusOptions = Object.keys(data).map(key => {
                    return {'key': key, 'value': data[key]}
                });
                this.loading_obj.status_options = false;
                this.check_loading();
            },
            async fetchUsers() {
                const {data} = await UsersRepository.get();
                this.users = data;
            },
            incrementDemandCount() {
                if (this.editedItem.demand_count == null) this.editedItem.demand_count = 1;
                else this.editedItem.demand_count = parseInt(this.editedItem.demand_count, 10) + 1;
            },
            decrementDemandCount() {
                this.editedItem.demand_count = parseInt(this.editedItem.demand_count, 10) - 1;
            },
            check_loading() {
                if (this.loading_obj.genders === false && this.loading_obj.churches === false && this.loading_obj.status_options === false) {
                    this.loading = false;
                }
            },
            formatDate(date) {
                if (!date) return null;

                const [year, month, day] = date.split('-');
                return `${day}.${month}.${year}`;
            },
            parseDate(date) {
                if (!date) return null;

                const [day, month, year] = date.split('.');
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            },
        },
        computed: {
            computedBirthday() {
                return this.editedItem.birthday
            },
        },
        watch: {
            dialog(visible) {
                if (visible) {
                    this.loading = true;
                    this.loading_obj = {
                        genders: true,
                        churches: true,
                        status_options: true,
                    };
                    this.fetchGenderOptions();
                    this.fetchChurches();
                    this.fetchStatusOptions();
                    if (this.users === undefined && this.$store.getters.isAdmin){
                        this.fetchUsers();
                    }
                }
            },
            birthday(val) {
                this.birthdayFormatted = this.formatDate(val);
                this.editedItem.birthday = val;
            },
            showDatePicker(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
            },
        }
    }
</script>

<style scoped>

</style>
